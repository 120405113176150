import React from 'react'
import { graphql } from 'gatsby'

import { H2, H3, H4 } from '../../components/typography'
import Link from '../../components/link'
import { Section, Banner, Col } from '../../components/section'
import PatternElement from '../../components/pattern-element'
import Image from '../../components/image'
import Header from '../../components/header'
import Footer from '../../components/footer'
import SEO from '../../components/seo'

import '../../styles/index.sass'
import socialImage from '../../images/social-berlinguide.jpg'
import patternElement1 from '../../images/pattern-element1.svg'
import patternElement2 from '../../images/pattern-element2.svg'

const BerlinGuide = ({ data }) => (
    <>
        <SEO
            title="Berlin Guide"
            description="Never been to Berlin and nervous? Or maybe you’ve been here before, but didn’t really get a proper feel for the city? Either way, we’ve prepared a list of both practical and entertaining tips for your visit to the capital of Germany."
            image={socialImage}
        />
        <Header linkTo="/2019">
            <H2 tilt="right" gutterBottom={false}>
                Berlin Guide
            </H2>
        </Header>
        <Section grid>
            <Col full>
                <p>
                    Never been to Berlin and nervous? Or maybe you’ve been here before, but didn’t
                    really get a proper feel for the city? Either way, we’ve prepared a list of both
                    practical and entertaining tips for your visit to the capital of Germany… the
                    very best, at least in the minds our spaCy IRL Berlin experts{' '}
                    <span role="img" aria-label="wink">
                        😉
                    </span>
                </p>
            </Col>

            <Col>
                <H4>Getting here</H4>
                <p>
                    Berlin has two airports within the city limits, <strong>Tegel (TXL)</strong> and{' '}
                    <strong>Schönefeld (SXF)</strong>, and both are about equal distance from the
                    city center and both excellent – in other words, it doesn’t really matter which
                    one you end up with. Both have easy public transport options (click{' '}
                    <Link to="https://www.berlin-airport.de/en/travellers-txl/to-and-from/buses-and-trains/local-public-transport/index.php">
                        here
                    </Link>{' '}
                    for Tegel and{' '}
                    <Link to="https://www.berlin-airport.de/en/travellers-sxf/to-and-from/buses-and-trains/local-public-transport/index.php">
                        here
                    </Link>{' '}
                    for Schönefeld) or you can grab a taxi and get into town for about €30.
                </p>

                <H4>Getting around</H4>

                <p>
                    Once you’re here, public transport and taxis are your two options for getting
                    from A-to-B in Berlin. Renting a car is expensive and a hassle – not to mention
                    not the greenest option. But there are apps for the two aforementioned transport
                    options. And of course there’s Uber, too.
                </p>

                <p>
                    <strong>The BVG:</strong> The BVG: Berlin’s public transport is well-connected,
                    reliable and runs 24/7 – you can grab single, day or week tickets at the
                    machines but it’s probably simplest to download the BVG App (from either the{' '}
                    <Link to="https://apps.apple.com/de/app/bvg-fahrinfo-plus-berlin/id284971745">
                        App Store
                    </Link>{' '}
                    or{' '}
                    <Link to="https://play.google.com/store/apps/details?id=de.eos.uptrade.android.fahrinfo.berlin&hl=en">
                        Google Play
                    </Link>
                    ), it’s available in English and you can use it to buy tickets and store them on
                    your mobile device without cash.
                </p>

                <p>
                    <strong>Taxis:</strong> Taxis are easy to hail anywhere, but as always: there’s
                    an app for that. "FREE NOW" (called MyTaxi up until this week) is the most
                    widely used app here in Berlin, so download that (
                    <Link to="https://apps.apple.com/us/app/mytaxi-tap-move-freely/id357852748">
                        App Store
                    </Link>{' '}
                    or{' '}
                    <Link to="https://play.google.com/store/apps/details?id=taxi.android.client&hl=en">
                        Google Play
                    </Link>
                    ) and you won’t miss Uber at all.
                </p>

                <p>
                    <strong>Uber:</strong> The world’s most recognizable ridesharing app had gotten
                    off to a very rocky start in Berlin and up until recently, wasn’t really a thing
                    here. If you’re most comfortable with Uber, you can now use it in Berlin, but
                    it's not nearly as ubiquitous here as it is in many cities, so you may sometimes
                    struggle to find a ride.
                </p>
            </Col>

            <Col>
                <Image
                    image={data.berlin1}
                    caption={
                        <>
                            <strong>The Berlin skyline</strong>{' '}
                            <em>
                                (
                                <Link to="https://unsplash.com/photos/LDMDCVtQqR4" hidden>
                                    Photo: Jonas Tebbe
                                </Link>
                                )
                            </em>
                        </>
                    }
                />
                <Image
                    image={data.berlin2}
                    tilt="right"
                    lower
                    caption={
                        <>
                            <strong>Overground subway station</strong>{' '}
                            <em>
                                (
                                <Link to="https://unsplash.com/photos/xO3HYCTHQGE" hidden>
                                    Photo: Wyron A
                                </Link>
                                )
                            </em>
                        </>
                    }
                />

                <Image
                    image={data.berlin4}
                    lower
                    caption={
                        <>
                            <strong>Inside the U1 train</strong>{' '}
                            <em>
                                (
                                <Link to="https://unsplash.com/photos/sTP9MVwdfAw" hidden>
                                    Photo: Daniel James
                                </Link>
                                )
                            </em>
                        </>
                    }
                />
            </Col>

            <Col>
                <H4>Where to stay</H4>
                <p>
                    If you haven’t got a place already, we fully recommend hotels. Airbnb (like
                    Uber) is complicated here. Many of our guests are staying in{' '}
                    <Link to="https://www.motel-one.com/en/hotels/berlin/">Motel One</Link>, a
                    reasonably priced and stylish hotel chain here in Europe. The closest is one
                    calls itself{' '}
                    <Link to="https://www.motel-one.com/en/hotels/berlin/hotel-berlin-mitte/">
                        Motel One – Mitte
                    </Link>
                    , but it’s basically Kreuzberg. The two areas outlying Kreuzberg – Potsdamer
                    Platz and Mitte – are also full of hotels and while a little further out, are
                    very simple to reach by public transport.
                </p>

                <Image
                    image={data.berlin3}
                    tilt="left"
                    lower
                    caption={
                        <>
                            <strong>Oberbaumbrücke</strong> connecting Kreuzberg and Friedrichshain{' '}
                            <em>
                                (
                                <Link
                                    to="https://www.visitberlin.de/en/districts/friedrichshain-kreuzberg"
                                    hidden
                                >
                                    Photo: Visit Berlin
                                </Link>
                                )
                            </em>
                        </>
                    }
                />
            </Col>

            <Col>
                <H4>About the areas</H4>
                <p>There are two districts of Berlin where much of the action happens:</p>
                <p>
                    <strong>Neukölln:</strong> spaCy IRL and its venue,{' '}
                    <Link to="https://goo.gl/maps/n8AmJw2af9um2zxq6">Heimathafen Neukölln</Link>, is
                    in Neukölln. Over the past decade, Neukölln has been one of Berlin’s fastest
                    growing districts. Primarily a Turkish and Arabic part of town, the recent
                    influx of young creatives has transformed the district into a melting pot of
                    city life, with restaurants and cafes on every corner.
                </p>
                <p>
                    <strong>Kreuzberg:</strong> The original alternative district, Kreuzberg is very
                    similar to Neukölln in terms of its populace, but a little more chill in terms
                    of visual surroundings. While full of interesting shops and restaurants, its
                    nightlife over the decades has become legendary. Much of it happens on
                    Kreuzberg’s main artery street,{' '}
                    <Link to="https://goo.gl/maps/jJxpNzRVMSvtHwke9">Oranienstraße</Link>.
                </p>
            </Col>

            <Col>
                <H4>Practical Tips &amp; Tricks</H4>
                <p>
                    <strong>Money:</strong> Cash is king in Berlin (and Germany). Keep in mind that
                    it’s a good idea to expect most places to be cash-only above all, so carry
                    accordingly.
                </p>
                <p>
                    <strong>Connectivity:</strong> Berlin’s Wi-Fi situation is okay and you should
                    be able to find hotspots in cafes and even on the public transit, but if staying
                    connected is important, getting a €10 prepaid package and SIM card is an easy
                    way to go.
                </p>
                <p>
                    <strong>Berlin Rhythm:</strong> For the most part, Berlin stays open late, so no
                    need to rush off to your nightlife plans at 19:00. And it follows that if Berlin
                    stays up late, it likes to sleep in, so most cafes won’t open before 9:00 or
                    10:00.
                </p>

                <p>
                    <strong>Sundays:</strong> Everything is closed on Sundays! Okay, restaurants,
                    bars and such remain open, but if you’re in need of toothpaste or toiletries,
                    hit up the shops Saturday evening at the latest.
                </p>
            </Col>

            <Col>
                <p>
                    <Image
                        image={data.berlin5}
                        tilt="right"
                        lower
                        caption={
                            <>
                                <strong>Touristy postcards</strong>
                                <em>
                                    (
                                    <Link to="https://unsplash.com/photos/LVWCSSwPlSU" hidden>
                                        Photo: Markus Spiske
                                    </Link>
                                    )
                                </em>
                            </>
                        }
                    />
                </p>

                <br />
                <br />

                <p>
                    <strong>Language:</strong> It’s Germany, so German is the official language, but
                    Berlin is a cosmopolitan city and English should get you by fairly easily,
                    particularly in the parts of town you’re probably in. There are some people who
                    can’t speak English though and people at day-to-day stops – banks, supermarkets
                    or drugstores – sometimes won’t, so it may be wise to grab a German speaker and
                    take them with you.
                </p>
            </Col>
        </Section>

        <PatternElement src={patternElement1} top={-80} left={50} />

        <Banner title="Where to go" id="where-to-go" theme="green" withPattern grid overlap>
            <Col full>
                <p>
                    Berlin is one of Europe’s most happening capital cities and aside from enjoying
                    your time with us, there’s plenty to enjoy outside of that. Here are a few tips:
                </p>
            </Col>

            <Col>
                <Image
                    image={data.eastsidegallery}
                    caption={
                        <>
                            <strong>East Side Gallery</strong>{' '}
                            <em>
                                (
                                <Link to="https://unsplash.com/photos/ZUaEZ_4Jw9k" hidden>
                                    Photo: Mar Cerdeira
                                </Link>
                                )
                            </em>
                        </>
                    }
                />

                <Image
                    image={data.brandenburggate}
                    lower
                    tilt="right"
                    caption={
                        <>
                            <strong>Brandenburg Gate</strong>{' '}
                            <em>
                                (
                                <Link to="https://unsplash.com/photos/Oog0wehKxYs" hidden>
                                    Photo: Håkon Sataøen
                                </Link>
                                )
                            </em>
                        </>
                    }
                />

                <Image image={data.altesmuseum} lower caption={<strong>Altes Museum</strong>} />

                <Image
                    image={data.tvtower}
                    lower
                    tilt="left"
                    caption={
                        <>
                            <strong>TV Tower</strong>{' '}
                            <em>
                                (
                                <Link to="https://unsplash.com/photos/GKfRxF784pk" hidden>
                                    Photo: Oskar Kadaksoo
                                </Link>
                                )
                            </em>
                        </>
                    }
                />
            </Col>

            <Col>
                <H3>Sights</H3>

                <p>
                    Berlin’s been through a lot, not just in the past century but over its 775 year
                    history. If you’re looking to do the proper tourist thing, here are the sites:
                </p>

                <p>
                    <strong>The Berlin Wall:</strong> The 3.6 meter high concrete slabs that blocked
                    in West Berlin (and kept East Germans out of West Berlin) still stands in a few
                    spots 30 years after its fall. Catch it in two different spots:{' '}
                    <Link to="https://goo.gl/maps/cdYhoSnusnYKQhC67">East Side Gallery</Link>{' '}
                    contains some Berlin’s most well-known art, including a mural from Keith Haring.
                    The <Link to="https://goo.gl/maps/tEhYVPdr6kSz3jW49">Berlin Wall Memorial</Link>{' '}
                    on Bernauer Straße is a much more somber affair and <em>really</em> gives you a
                    feeling about the Berlin Wall was about. Both are free.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/jwcnmvcVRawk5Em5A">The Brandenburg Gate</Link>:
                    Simply the symbol of Berlin. It’s situated between the American and French
                    Embassies on Pariser Platz at the end of the historic Unter den Linden street.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/22K3iyTBLgvjtCNN9">Museum Island</Link>: If you
                    want the classic tourist experience, Berlin’s rich with museums. And five of its
                    most famous are situated all in one spot. The{' '}
                    <Link to="https://goo.gl/maps/LFxnNB4dmkzmfeUS8">Altes Museum</Link> (Old
                    Museum), the{' '}
                    <Link to="https://goo.gl/maps/NAMB6PzNEv6zeYBs7">Neues Museum</Link> (New Museum
                    – which contains both the bust of Nefertiti and the famed Golden Hat of Berlin),
                    the <Link to="https://goo.gl/maps/U2NwnaJ2g2cBj1fx9">Alte Nationalgalerie</Link>{' '}
                    (Old National Gallery), the{' '}
                    <Link to="https://goo.gl/maps/6KPrsV9RZibNY8226">Bode Museum</Link> and the{' '}
                    <Link to="https://goo.gl/maps/yiPitXCT8H2QRU9N6">Pergamon Museum</Link> are all
                    found on the island.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/1k8XbbGvubNUmAeg6">The TV Tower</Link>: The
                    defining feature of Berlin’s skyline! Once a broadcasting point in the
                    former-GDR, Berlin’s TV Tower is its most central attraction and provides a
                    panoramic view of the city from 203 meters up. There’s also a restaurant. Book
                    ahead <Link to="https://tv-turm.de/en/tickets-prices/">here</Link>.
                </p>
            </Col>

            <Col>
                <H3>German Food</H3>

                <p>
                    Berlin’s culinary scene is pretty diverse, but if you’re looking to try the
                    ‘local’ cuisine, there a few German restaurants worth checking out. German food
                    is pretty meat-heavy, so be warned. There are always vegetarian options of
                    course, but your selection is limited.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/TeAGhQNrbv4YngN87">Wirsthaus Max und Moritz</Link>
                    : Around since 1902, Max und Moritz is a landmark-protected German restaurant
                    with a large ‘tavern’ like feel. As traditional as it comes, go there for the
                    atmosphere as much as the grub.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/uwF5u2dCi4hrwEiW9">Kuchen-Kaiser</Link>:
                    Kuchen-Kaiser has Max und Moritz beat in terms of age, opening in 1866. Outlying
                    Kreuzberg’s famous Oranienplatz, the restaurant’s cuisine still does it
                    traditional, but the atmosphere, staff, price and clientele have a much more
                    distinct Kreuzberg vibe.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/gBZWfULAEHprhJoS9">Drei Schwestern</Link>: The
                    restaurant itself has only been around since 2010 but it’s situated in
                    Kreuzberg’s oldest building: Bethanien, a former hospital built in 1846 that has
                    transformed into a cultural center over the past five decades after being
                    squatted in 1973. Don’t be fooled by how new the restaurant is – it comes highly
                    recommended by the locals.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/5rTCZS2G6oxNjXqY6">Mustafa’s Gemüse Kebap</Link>:{' '}
                    Okay, whether it’s German is debatable, but if you’re looking to try Berlin’s
                    most well-known street food, Mustafa’s is the standard-bearer and with that
                    title, come very long lines – so be prepared.
                </p>
            </Col>

            <Col>
                <Image
                    image={data.maxmoritz}
                    tilt="right"
                    caption={
                        <Link to="https://goo.gl/maps/TeAGhQNrbv4YngN87" hidden>
                            <strong>Max und Moritz</strong> &middot; Oranienstraße 162
                        </Link>
                    }
                />
                <Image
                    image={data.dreischwestern}
                    lower
                    caption={
                        <Link to="https://goo.gl/maps/gBZWfULAEHprhJoS9" hidden>
                            <strong>Drei Schwestern</strong> &middot; Mariannenplatz 2
                        </Link>
                    }
                />

                <Image
                    image={data.mustafas}
                    tilt="left"
                    lower
                    caption={
                        <>
                            <Link to="https://goo.gl/maps/5rTCZS2G6oxNjXqY6" hidden>
                                <strong>Mustafa’s Gemüse Kebap</strong> &middot; Mehringdamm 32
                            </Link>{' '}
                            <em>
                                (
                                <Link
                                    to="https://www.tripadvisor.com/Restaurant_Review-g187323-d1341041-Reviews-Mustafa_s_Gemuese_Kebab-Berlin.html#photos;aggregationId=101&albumid=101&filter=7&ff=326858647
                        "
                                    hidden
                                >
                                    Photo: alexclc99, Tripadvisor
                                </Link>
                                )
                            </em>
                        </>
                    }
                />
            </Col>

            <Col>
                <Image
                    image={data.lalucha}
                    tilt="left"
                    caption={
                        <Link to="https://goo.gl/maps/t5ftLi9GAwsUX81GA" hidden>
                            <strong>La Lucha</strong> &middot; Paul-Lincke-Ufer 41
                        </Link>
                    }
                />
            </Col>
            <Col>
                <H3>Non-German Food</H3>

                <p>
                    Just like nightlife and bars, grub is up to everyone’s individual taste, but if
                    you’re looking for something yummy, unique and Berlin, we recommend ‘food row’
                    on Paul-Lincke-Ufer. The northern side of the Landwehr Canal is littered with
                    different dining options, including modern Mexican (
                    <Link to="https://goo.gl/maps/t5ftLi9GAwsUX81GA">La Lucha</Link>
                    ), wood-oven baked pizza (
                    <Link to="https://goo.gl/maps/v1jJZpweQiXTaeSr7">Zola</Link>), the always
                    popular ramen (
                    <Link to="https://goo.gl/maps/pH38tXaufRe29w849">Cocolo Ramen</Link>) and more.
                </p>
            </Col>

            <Col>
                <H3>
                    Eats in the Area
                    <H4>of spaCy IRL</H4>
                </H3>

                <p>
                    Wanna sneak a quick bite on the way to, during or after the conference? Here are
                    a couple of places within walking distance:
                </p>

                <p>
                    <Link to="https://goo.gl/maps/zVQP34dSFMqARCEd8">Azzam</Link>: If you do indeed
                    need a fast bite but don’t want to sacrifice taste, Azzam can’t be beat.
                    Renowned for their falafel and hummus, Azzam offers the best Middle Eastern fast
                    food on Sonnenallee.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/4HSxXMypusBemjj58">Café Botanico</Link>: If you
                    want to cap off the conference with a classy yet casual affair, Botanico offers
                    Italian cuisine almost directly behind Heimathafen Neukölln, with many of their
                    ingredients grown right there in their own garden.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/SaaxBGktxWg1jx777">Wonder Waffel</Link>: The
                    world’s first fruit doner kebab? It may just be fruit and sweets tucked into a
                    Belgian waffle, but if you’ve got a sweet tooth to satisfy, it’s a colorful and
                    fun option.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/T5B72tuadDucaHfs6">Damaskus Konditorei</Link>: If
                    you’re looking to try something that reflects the diversity of Neukölln, Syrian
                    confectionery Damaskus delivers the sweet side of life rich in flavor, like
                    their pistachio Mabruma.
                </p>
            </Col>

            <Col>
                <Image
                    image={data.azzam}
                    tilt="right"
                    caption={
                        <Link to="https://goo.gl/maps/zVQP34dSFMqARCEd8" hidden>
                            <strong>Azzam</strong> &middot; Sonnenallee 54
                        </Link>
                    }
                />
                <Image
                    image={data.botanico}
                    lower
                    caption={
                        <Link to="https://goo.gl/maps/4HSxXMypusBemjj58" hidden>
                            <strong>Café Botanico</strong> &middot; Richardstraße 100
                        </Link>
                    }
                />
                <Image
                    image={data.wonderwaffel}
                    tilt="left"
                    lower
                    caption={
                        <Link to="https://goo.gl/maps/SaaxBGktxWg1jx777" hidden>
                            <strong>Wonder Waffel</strong> &middot; Neckarstraße 2
                        </Link>
                    }
                />
            </Col>
        </Banner>

        <PatternElement src={patternElement2} top={-120} left={50} />

        <Banner title="What to do" id="what-to-do" theme="blue" grid withPattern>
            <Col full>
                <p>If you just want to soak up the city life, Berlin’s rife with stuff to do!</p>
            </Col>

            <Col>
                <p>
                    The <Link to="https://goo.gl/maps/cRQgg6JxkYRUKXN59">Maybachufer Market</Link>,
                    also known as the Turkish Market: Every single Tuesday and Friday from
                    11:00-18:30, Maybachufer, the street along the Landwehr Canal, fills up with
                    fruit, vegetable and food stands of all sorts. Whether you want produce for a
                    picnic along the canal or just want to check out its ambiance, the market is a
                    Kreuzberg-Neukölln staple.
                </p>

                <p>
                    The <Link to="https://goo.gl/maps/zWHPn89qLFKKLd8X8">Computerspielemuseum</Link>{' '}
                    (Computer Games Museum): As much a museum as it is an interactive history tour
                    and play space, the museum has dedicated itself to preserving digital
                    interactive entertainment culture since 1997. Located on former East Berlin’s
                    impressive Karl-Marx-Allee boulevard.
                </p>

                <p>
                    <strong>Open-air cinemas:</strong> Movies might not sound intuitive on a summer
                    night, but watching films on the big screen under the stars is a Berlin
                    tradition. Grab a beer and pull up a deck chair and relax to some
                    well-programmed art house film, mostly in original language. These two are close
                    to spaCy IRL and come fully recommended:{' '}
                    <Link to="https://goo.gl/maps/gSZh474Xnycr6EXH8">Freiluftkino Kreuzberg</Link>{' '}
                    and{' '}
                    <Link to="https://goo.gl/maps/2GDfj3EgVMeTnTa26">Freiluftkino Hasenheide</Link>.
                </p>

                <p>
                    <strong>Bars:</strong> Everyone’s tastes differ in this arena, so we’ll rather
                    tell you where to go:{' '}
                    <Link to="https://goo.gl/maps/jJxpNzRVMSvtHwke9">Oranienstraße</Link> in
                    Kreuzberg and{' '}
                    <Link to="https://goo.gl/maps/9itZxbvZ6Kwo8Eao8">Weserstraße</Link> in Neukölln.
                    Along these two strips, you’ll anything for any taste, from fancy cocktails to a
                    raucous punk rock bar, from cafes to gay spots. And with just as much food as
                    imbibing options, you could spend a whole night on either of these streets.
                </p>

                <p>
                    <strong>Queer/LGBT:</strong> Berlin’s reputation as a haven for queer and LGBT
                    people is over 100 years old and that hasn’t changed in the 21st century. If you
                    want to see a bit of Berlin’s LGBT history, head over to the{' '}
                    <Link to="https://goo.gl/maps/Q11FkUiPK7GX99eV9">Schöneberg</Link> district. The
                    early gay rights movement is said to have started here. Today it’s mostly a gay
                    (men’s) area of town with cafes, shops, bars and clubs. For something more
                    diverse and purely nightlife,{' '}
                    <Link to="https://goo.gl/maps/CjUBHV1E4GZt4Fb1A">SchwuZ</Link> is over 40 years
                    old, dedicated to providing something for all queers (the program changes every
                    evening) and is situated in Neukölln.
                </p>

                <p>
                    <strong>Flea Markets:</strong> Flea markets are ubiquitous in Berlin and a great
                    place to get some one-of-a-kind items.{' '}
                    <Link to="https://goo.gl/maps/4SYCYU9ti3JAnxNk9">Mauerpark</Link> in the
                    northern district of Prenzlauer Berg is undoubtedly the most famous, but it gets
                    packed. For something smaller but just as hip, try out the{' '}
                    <Link to="https://goo.gl/maps/yvK4QTJ1LTFXyYJ68">Boxhagener Platz</Link> Flea
                    Market in Friedrichshain, on every Sunday 10:00-18:00.
                </p>

                <p>
                    <Link to="https://goo.gl/maps/hjz4B3X5yrvchXgbA">Tempelhofer Feld</Link>: If
                    outdoors are your thing, a simple way to spend a day in the sun is on
                    Tempelhofer Feld. Formerly an active airport in the middle of the city, after
                    its disuse the city converted into a vast park. You can find everyone from
                    enthusiastic community gardeners to ecstatic rollerbladers to large families
                    having a BBQ there.
                </p>
            </Col>

            <Col>
                <Image
                    image={data.computergamemuseum}
                    tilt="left"
                    caption={
                        <>
                            <strong>Computerspielemuseum</strong>{' '}
                            <em>
                                (
                                <Link to="https://www.instagram.com/p/BN1Y61XBaEG/" hidden>
                                    Photo: Instagram
                                </Link>
                                )
                            </em>
                        </>
                    }
                />

                <Image
                    image={data.freiluftkino}
                    lower
                    caption={
                        <Link to="https://goo.gl/maps/gSZh474Xnycr6EXH8" hidden>
                            <strong>Freiluftkino Kreuzberg</strong> &middot; Mariannenplatz 2
                        </Link>
                    }
                />

                <Image
                    image={data.schwuz}
                    tilt="right"
                    lower
                    caption={
                        <>
                            <Link to="https://goo.gl/maps/CjUBHV1E4GZt4Fb1A" hidden>
                                <strong>SchwuZ</strong> &middot; Rollberstraße 26
                            </Link>{' '}
                            <em>(Photo: Guido Woller)</em>
                        </>
                    }
                />

                <Image
                    image={data.boxi}
                    lower
                    caption={
                        <>
                            <strong>Boxhagener Platz Flea Market</strong>{' '}
                            <em>
                                (
                                <Link
                                    to="https://www.visitberlin.de/en/blog/top-11-berlin-flea-markets"
                                    hidden
                                >
                                    Photo: Visit Berlin
                                </Link>
                                )
                            </em>
                        </>
                    }
                />

                <Image
                    image={data.tempelhof}
                    tilt="left"
                    lower
                    caption={
                        <>
                            <strong>Tempelhofer Feld</strong> <em>(Photo: Nic Simanek)</em>
                        </>
                    }
                />
            </Col>
        </Banner>

        <Section>
            <H3 tilt="right">Looking forward to seeing you at spaCy IRL!</H3>

            <p>
                We hope our tips for Berlin make your stay, and of course the conference, one of the
                most exciting you’ve ever had! <Link to="/2019">Go to conference site &rarr;</Link>
            </p>
        </Section>

        <Footer />
    </>
)

export default BerlinGuide

export const query = graphql`
    query {
        maxmoritz: file(relativePath: { eq: "berlin-guide/max-moritz.jpg" }) {
            ...guideImage
        }
        dreischwestern: file(relativePath: { eq: "berlin-guide/3schwestern.jpg" }) {
            ...guideImage
        }
        mustafas: file(relativePath: { eq: "berlin-guide/mustafas.jpg" }) {
            ...guideImage
        }
        lalucha: file(relativePath: { eq: "berlin-guide/lalucha.jpg" }) {
            ...guideImage
        }
        azzam: file(relativePath: { eq: "berlin-guide/azzam.jpg" }) {
            ...guideImage
        }
        botanico: file(relativePath: { eq: "berlin-guide/botanico.jpg" }) {
            ...guideImage
        }
        wonderwaffel: file(relativePath: { eq: "berlin-guide/wonderwaffel.jpg" }) {
            ...guideImage
        }
        eastsidegallery: file(relativePath: { eq: "berlin-guide/eastsidegallery.jpg" }) {
            ...guideImage
        }
        brandenburggate: file(relativePath: { eq: "berlin-guide/brandenburggate.jpg" }) {
            ...guideImage
        }
        tvtower: file(relativePath: { eq: "berlin-guide/tvtower.jpg" }) {
            ...guideImage
        }
        altesmuseum: file(relativePath: { eq: "berlin-guide/altesmuseum.jpg" }) {
            ...guideImage
        }
        computergamemuseum: file(relativePath: { eq: "berlin-guide/computergamemuseum.jpg" }) {
            ...guideImage
        }
        freiluftkino: file(relativePath: { eq: "berlin-guide/freiluftkino.jpg" }) {
            ...guideImage
        }
        schwuz: file(relativePath: { eq: "berlin-guide/schwuz.jpg" }) {
            ...guideImage
        }
        boxi: file(relativePath: { eq: "berlin-guide/boxi.jpg" }) {
            ...guideImage
        }
        tempelhof: file(relativePath: { eq: "berlin-guide/tempelhof.jpg" }) {
            ...guideImage
        }
        berlin1: file(relativePath: { eq: "berlin-guide/berlin1.jpg" }) {
            ...guideImage
        }
        berlin2: file(relativePath: { eq: "berlin-guide/berlin2.jpg" }) {
            ...guideImage
        }
        berlin3: file(relativePath: { eq: "berlin-guide/berlin3.jpg" }) {
            ...guideImage
        }
        berlin4: file(relativePath: { eq: "berlin-guide/berlin4.jpg" }) {
            ...guideImage
        }
        berlin5: file(relativePath: { eq: "berlin-guide/berlin5.jpg" }) {
            ...guideImage
        }
    }
`

export const guideImage = graphql`
    fragment guideImage on File {
        childImageSharp {
            fluid(maxWidth: 450, quality: 100) {
                ...GatsbyImageSharpFluid
            }
        }
    }
`
